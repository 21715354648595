import React from 'react';
import styles from './Premium.module.scss';

import selo from '../images/selo.svg';
import googlePlay from '../images/googlePlay.svg';
import appStore from '../images/appStore.svg';

interface PremiumProps {
  advantages: Array<{
    icon: string;
    title: string;
    description: string;
  }>;
}
const Premium = ({ advantages }: PremiumProps): React.ReactElement => {
  return (
    <div id="premium" className={styles.premiumWrapper}>
      <div className={styles.premiumAdvantagesContainer}>
        <h2 className={styles.premiumTitle}>Adere ao Premium</h2>
        <div className={styles.advantagesContainer}>
          {advantages.map((advantage, i) => (
            <div className={styles.advantage} key={i}>
              <img src={advantage.icon} className={styles.advantageIcon} alt="" />
              <h4 className={styles.advantageTitle}>{advantage.title}</h4>
              <p className={styles.advantageDescription}>{advantage.description}</p>
            </div>
          ))}
        </div>
      </div>
      {/* <div className={styles.downloadContainer}>
        <h5 className={styles.cta}>Regista-te até 31 de Março e ganha</h5>
        <div className={styles.badgeContainer}>
          <img src={selo} alt=" " />
        </div>
        <div className={styles.ctaContainer}>
          <div className={styles.linksContainer}>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://play.google.com/store/apps/details?id=com.bloomidea.suop"
              className={styles.link}
            >
              <img src={googlePlay} alt="" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://apps.apple.com/us/app/suop/id1537434646"
              className={styles.link}
            >
              <img src={appStore} alt=" " />
            </a>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Premium;
