import React from 'react';
import styles from './CTA.module.scss';
import googlePlay from '../images/googlePlay.svg';
import appStore from '../images/appStore.svg';

const CTA = (): React.ReactElement => {
  return (
    <div className={styles.ctaWrapper}>
      <div className={styles.ctaContainer}>
        <h2 className={styles.ctaTitle}>Descarrega já!</h2>
        <div className={styles.linksContainer}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://play.google.com/store/apps/details?id=com.bloomidea.suop"
            className={styles.link}
          >
            <img src={googlePlay} alt="" />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://apps.apple.com/us/app/suop/id1537434646"
            className={styles.link}
          >
            <img src={appStore} alt=" " />
          </a>
        </div>
      </div>
    </div>
  );
};

export default CTA;
