import React from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import styles from './NewsSection.module.scss';

interface NewsSectionProps {
  news: Array<{
    link: string;
    logo: string;
    date: string;
    title: string;
    extract: string;
  }>;
}
const NewsSection = ({ news }: NewsSectionProps): React.ReactElement => {
  return (
    <div id="newsSection" className={styles.newsSection}>
      <h2 className={styles.newsSectionTitle}>Notícias</h2>
      <div className={styles.newsContainer}>
        {news.map((article, i) => (
          <div key={i} className={styles.articleContainer}>
            <div className={styles.backCard}></div>
            <div className={styles.backCard}></div>
            <div className={styles.article}>
              <div className={styles.imageContainer}>
                <img src={article.logo} alt=""></img>
              </div>
              <div className={styles.content}>
                <span className={styles.date}>{article.date}</span>
                <span className={styles.title}>{article.title}</span>
                <p className={styles.extract}>{article.extract}</p>
              </div>
              <div className={styles.linkContainer}>
                <a target="_blank" rel="noreferrer" className={styles.link} href={article.link}>
                  Saber Mais
                  <FaExternalLinkAlt className={styles.linkIcon}></FaExternalLinkAlt>
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NewsSection;
