import React from 'react';

import '../styles/main.scss';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Banner from '../components/Banner';
import Hero from '../components/Hero';
import About from '../components/About';

// ABOUT Icons
import addButton from '../images/icons/add-button.svg';
import download from '../images/icons/download.svg';
import match from '../images/icons/match.svg';
import swipe from '../images/icons/swipe.svg';
import speech from '../images/icons/speech-bubble.svg';
import Advantages from '../components/Advantages';
import Premium from '../components/Premium';
import Footer from '../components/Footer';

import xRay from '../images/icons/xRay.svg';
import euro from '../images/icons/euro.svg';
import infinite from '../images/icons/infinite.svg';
import rocket from '../images/icons/rocket.svg';
import Items from '../components/Items';
import CTA from '../components/CTA';

import ogImage from '../images/ogImage.png';
import NewsSection from '../components/NewsSection';

import news01 from '../images/news01.jpg';
import news02 from '../images/news02.jpg';
import news03 from '../images/news03.jpg';
import news04 from '../images/news04.jpg';

const IndexPage = () => (
  <>
    <SEO
      title="Suop"
      description="O usado é o novo novo"
      url="https://suopapp.com/"
      imageUrl={ogImage}
    />
    {/* <Banner></Banner> */}
    <Hero></Hero>
    <About
      steps={[
        {
          icon: download,
          title: '1 - Descarrega a App',
          description: 'Através do Google Play ou da App Store.',
        },
        {
          icon: addButton,
          title: '2 - Adiciona',
          description: 'Adiciona o que tens para trocar.',
        },
        {
          icon: swipe,
          title: '3 - Faz Swipe',
          description:
            'Faz swipe para a direita se gostares, e swipe para a esquerda se não te interessar. ',
        },
        { icon: match, title: '4 - Match!', description: 'Espera que o MATCH aconteça' },
        {
          icon: speech,
          title: '5 - Conversa',
          description:
            'Fala e discute directamente com o outro membro todos os pormenores da troca.',
        },
      ]}
    ></About>
    <Advantages></Advantages>
    <Items></Items>
    <NewsSection
      news={[
        {
          link: 'https://recicla.pt/ideias-sustentaveis/chegou-a-app-das-trocas/',
          logo: news04,
          date: '10 Maio 2021',
          title: 'Chegou a app das trocas',
          extract:
            'Imagine que está a preparar o verão e já não quer a sua mala de praia, mas, sim, uns óculos de sol estilosos. Não era ótimo se, em vez de descartar o que não lhe faz falta e comprar algo novo, simplesmente trocasse uma coisa por outra? Agora já pode! Faça Suop.',
        },
        {
          link:
            'https://www.noticiasmagazine.pt/2021/suop-a-aplicacao-que-permite-troca-por-troca/historias/262617/',
          logo: news01,
          date: '07 Maio 2021',
          title: 'Suop: a aplicação que permite troca por troca',
          extract:
            'Aplicação 100% portuguesa dá nova vida a objetos sem intermediários e sem transações comerciais. Poupa-se dinheiro, poupa-se o Planeta.',
        },
        {
          link:
            'https://www.noticiasdecoimbra.pt/aplicacao-suop-da-nova-vida-aos-objetos-atraves-da-troca/',
          logo: news03,
          date: '14 Abril 2021',
          title: 'Aplicação Suop dá nova vida aos objetos através da troca',
          extract:
            'A app Suop é ecofriendly, intuitiva e sem custos associados, e possibilita a troca de objetos que não tenham utilidade para o utilizador por outros do seu interesse. ',
        },
        {
          link: 'https://echoboomer.pt/suop-app-dedicada-a-troca-de-produtos/',
          logo: news02,
          date: '30 Janeiro 2021',
          title: 'Suop. Esta app permite trocar tudo o que não precisam por aquilo que mais querem',
          extract:
            'Querem obter livros, equipamentos, mobília e outros objetos sem gastar dinheiro? Então experimentem esta novidade.',
        },

        // {
        //   link: 'https://observador.pt/',
        //   logo: news01,
        //   date: '25 Setembro 2021',
        //   title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        //   extract:
        //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat tellus felis non, nullam enim, tincidunt elementum. Massa, pharetra et ante sed. Volutpat tellus felis non.',
        // },
        // {
        //   link: 'https://observador.pt/',
        //   logo: news01,
        //   date: '25 Setembro 2021',
        //   title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        //   extract:
        //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat tellus felis non, nullam enim, tincidunt elementum. Massa, pharetra et ante sed. Volutpat tellus felis non.',
        // },
      ]}
    ></NewsSection>
    <Premium
      advantages={[
        {
          icon: rocket,
          title: 'Destacar Anúncios',
          description: 'Garantir que estás no topo dos items anunciados',
        },
        {
          icon: euro,
          title: 'Intervalo de Valores',
          description: 'Ver apenas o que está ao teu alcance trocar',
        },
        {
          icon: infinite,
          title: 'Itens Ilimitados',
          description: 'Anunciar ilimitadamente',
        },

        {
          icon: xRay,
          title: 'Visão Raio-X',
          description: 'Saber quem tem interesse nos teus anúncios',
        },
      ]}
    ></Premium>
    <CTA></CTA>
    <Footer></Footer>
  </>
);

export default IndexPage;
