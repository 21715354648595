import React from 'react';
import styles from './Items.module.scss';
const Items = (): React.ReactElement => {
  return (
    <div className={styles.itemsWrapper}>
      <span>
        Com a Suop encontras
        <br />
        tudo o que possas imaginar
      </span>
    </div>
  );
};

export default Items;
