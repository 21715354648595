import React from 'react';
import styles from './Hero.module.scss';

import logo from '../images/logo.svg';
import googlePlay from '../images/googlePlay.svg';
import appStore from '../images/appStore.svg';
import heroMobileImage from '../images/heroMobile.png';

const Hero = (): React.ReactElement => {
  return (
    <div className={styles.heroWrapper}>
      <div className={styles.heroContainer}>
        <div className={styles.infoContainer}>
          <img className={styles.logo} src={logo} alt=" " />
          <h1 className={styles.heroTitle}>O usado é o novo novo</h1>
          <p className={styles.heroParagraph}>
            Se nem tu próprio sabes o que tens e já não usas, imagina o que podes encontrar num
            mundo onde todos trocam os seus pertences.
          </p>
          <p className={styles.heroParagraph}>
            Não deixes que o passado te ocupe o espaço do presente. Com a Suop podes trocar o que
            não precisas por aquilo que queres, de uma maneira fácil, rápida e segura.
            <br />
            Do que estás à espera?
          </p>
          <div className={styles.linksContainer}>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://play.google.com/store/apps/details?id=com.bloomidea.suop"
              className={styles.link}
            >
              <img src={googlePlay} alt="" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://apps.apple.com/us/app/suop/id1537434646"
              className={styles.link}
            >
              <img src={appStore} alt=" " />
            </a>
          </div>
        </div>
        <div className={styles.imageContainer}>
          <img src={heroMobileImage} alt=" " />
        </div>
      </div>
    </div>
  );
};

export default Hero;
