import React from 'react';
import styles from './Advantages.module.scss';

import mobileImage from '../images/advantagesMobileImage.png';

const Advantages = (): React.ReactElement => {
  return (
    <div className={styles.advantagesWrapper}>
      <div className={styles.advantagesContainer}>
        <h2 className={styles.advantagesTitle}>Todas as partes ganham</h2>
        <div className={styles.advantagesInnerContainer}>
          <div className={styles.advantagesColumn}>
            <div className={styles.advantage}>
              <h3 className={styles.advantageTitle}>Ecológico</h3>
              <p className={styles.advantageDescription}>
                Um objecto tem muito mais vida do que aquela que lhe damos. Deitá-lo fora porque já
                não satisfaz as nossas necessidades, não é solução. Ao trocar, não encorajamos a
                produção e o consumo em massa.
              </p>
            </div>
            <div className={styles.advantage}>
              <h3 className={styles.advantageTitle}>Sem comissões</h3>
              <p className={styles.advantageDescription}>
                Não há dinheiro envolvido. Tanto não pagas para anunciar, como não pagas o que
                adquires.
              </p>
            </div>
          </div>
          <div className={styles.imageContainer}>
            <img src={mobileImage} alt=" " />
          </div>
          <div className={styles.advantagesColumn}>
            <div className={styles.advantage}>
              <h3 className={styles.advantageTitle}>Seguro</h3>
              <p className={styles.advantageDescription}>
                Queremos respeitar a tua privacidade, por isso garantimos que as tuas informações
                pessoais não serão partilhadas ou divulgadas por nós.
              </p>
            </div>
            <div className={styles.advantage}>
              <h3 className={styles.advantageTitle}>Sem intervenientes</h3>
              <p className={styles.advantageDescription}>
                Uma troca só envolve duas partes: entre ti e a tua nova aquisição, está uma janela
                de chat para que a magia aconteça da forma que fôr mais conveniente a ambas as
                partes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advantages;
