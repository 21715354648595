import React from 'react';
import Helmet from 'react-helmet';

interface SEOProps {
  title: string;
  description: string;
  url: string;
  imageUrl: string;
  meta?: Array<
    | { name: string; content: any; property?: undefined }
    | { property: string; content: any; name?: undefined }
  >;
}

function SEO({ title, description, url, imageUrl, meta = [] }: SEOProps): React.ReactElement {
  const metaList = [
    {
      property: 'og:type',
      content: 'website',
    },
    {
      name: 'twitter:card',
      content: 'summary',
    },
  ].concat(meta);

  if (title) {
    metaList.push(
      ...[
        {
          property: 'og:title',
          content: title,
        },
        {
          name: 'twitter:title',
          content: title,
        },
      ],
    );
  }

  if (description) {
    metaList.push(
      ...[
        {
          name: 'description',
          content: description,
        },
        {
          property: 'og:description',
          content: description,
        },
        {
          name: 'twitter:description',
          content: description,
        },
      ],
    );
  }

  if (url) {
    metaList.push({
      property: 'og:url',
      content: url,
    });
  }

  if (imageUrl) {
    metaList.push({
      property: 'og:image',
      content: imageUrl,
    });
  }

  metaList.push(
    ...[
      {
        name: 'author',
        content: 'Mosca Digital',
      },
      {
        name: 'twitter:creator',
        content: 'Mosca Digital',
      },
    ],
  );

  return <Helmet title={title} meta={metaList} />;
}

export default SEO;
