import React from 'react';
import styles from './About.module.scss';

interface AboutProps {
  steps: Array<{
    icon: string;
    title: string;
    description: string;
  }>;
}
const About = ({ steps }: AboutProps): React.ReactElement => {
  return (
    <div className={styles.aboutWrapper}>
      <h2 className={styles.aboutTitle}>Como funciona</h2>
      <div className={styles.stepsContainer}>
        {steps.map((step, i) => (
          <div className={styles.stepRow} key={i}>
            <div className={styles.step}>
              <img src={step.icon} className={styles.stepIcon} alt="" />
              <div className={styles.stepInfoContainer}>
                <h3 className={styles.stepTitle}>{step.title}</h3>
                <p className={styles.stepDescription}>{step.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default About;
